import { CSSProperties } from 'react';

import { Grid, useMediaQuery } from '@mui/material';

import { TwContainer } from '../../typings/tw.types';
import classNames from 'classnames';

interface GapAroundProps extends TwContainer {
  spaceTaken?: number;
  containerStyle?: CSSProperties;
}

const GapAround = ({ children, spaceTaken = 0, containerStyle, childrenClasses }: GapAroundProps) => {
  const isLessThan1450 = useMediaQuery('(max-width: 1150px)');
  return (
    <Grid container style={{ position: 'relative', height: '100%', ...containerStyle }}>
      {!isLessThan1450 && <Grid item xs={spaceTaken}></Grid>}
      <Grid item xs={isLessThan1450 ? 12 : 12 - 2 * spaceTaken}>
        <div className={classNames(childrenClasses)}>{children}</div>
      </Grid>
      {!isLessThan1450 && <Grid item xs={spaceTaken}></Grid>}
    </Grid>
  );
};

export default GapAround;
