import { imageUrl } from '../../utils';

export const FlowerOne = () => {
  const tiltedFlower = imageUrl('tilt-flower.svg');
  return (
    <div className="absolute left-0 top-[30vh] z-[0.9] hidden xl:block">
      <img src={tiltedFlower} alt="" />
    </div>
  );
};

export const FlowerTwo = () => {
  const antiTiltFlower = imageUrl('anti-tilt-flower.svg');
  return (
    <div className="absolute right-[0] top-[90vh] -z-10 overflow-hidden breaktwo:hidden">
      <img className="translate-x-20" src={antiTiltFlower} alt="" />
    </div>
  );
};

export const FlowerThree = () => {
  return (
    <div className="absolute left-0 top-[100rem] z-[0.9] breaktwo:hidden">
      <img src={imageUrl('single_flower_right.svg')} alt="" />
    </div>
  );
};
