import { Typography } from '@mui/material';
import GapAround from '../../components/hoc/GapAround';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import classNames from 'classnames';
import { imageUrl } from '../../utils';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mui/material';
import { Link as ScrollLink } from 'react-scroll';
import { useNavigate, useLocation } from 'react-router-dom';
import { useCallback } from 'react';
import { linkOffsets } from '../../utils';

const whiteLogo = imageUrl('atini-logo-white.svg');

const FooterColumn = ({ data, noCursor }: { data: string[]; noCursor?: boolean }) => {
  const headingClasses = 'text-white mb-5 breaktwo:mb-2';
  const subHeadingClasses = classNames(headingClasses, {
    'cursor-pointer': !noCursor,
    'hover:text-yellow': !noCursor,
    'transition-colors duration-200': !noCursor,
  });

  const navigate = useNavigate();
  const location = useLocation();
  const isHomePage = location.pathname === '/';

  const handleFooterlinkClick = useCallback(
    (param: string, index: number) => {
      if (isHomePage) return;
      navigate('/');
      const elementId =
        param === 'Reviews'
          ? 'clients'
          : param === "FAQ's"
          ? 'faqs'
          : // : param === 'Our Team'
            // ? 'our-team'
            param.toLowerCase();
      // if (elementId === 'our-team') {
      //   navigate('/our-team');
      // }
      setTimeout(() => {
        document.getElementById(`${elementId}link`)?.click();
        const offset = linkOffsets[index];
        window.scrollTo({ top: offset, behavior: 'smooth' });
      }, 500);
    },
    [isHomePage, navigate],
  );

  return (
    <div className="justify-between">
      {data.map((each, index) => (
        <div key={index} className={index === 0 ? headingClasses : subHeadingClasses}>
          {noCursor || index === 0 ? (
            <Typography
              variant={index === 0 ? 'subtitle1' : 'subtitle2'}
              style={{
                fontFamily: index === 0 ? 'Plus Jakarta Sans, sans-serif' : 'ABeeZee, sans-serif',
                fontSize: index === 0 ? undefined : '100%',
                fontWeight: index === 0 ? undefined : 100,
              }}>
              {each}
            </Typography>
          ) : (
            // ) : each === 'Our Team' ? (
            //   <a
            //     href="/our-team"
            //     onClick={(e) => {
            //       e.preventDefault();
            //       navigate('/our-team');
            //     }}
            //     className={subHeadingClasses}>
            //     <Typography
            //       variant={index === 0 ? 'subtitle1' : 'subtitle2'}
            //       style={{
            //         fontFamily: index === 0 ? 'Plus Jakarta Sans, sans-serif' : 'ABeeZee, sans-serif',
            //         fontSize: index === 0 ? undefined : '100%',
            //         fontWeight: index === 0 ? undefined : 100,
            //       }}>
            //       {each}
            //     </Typography>
            //   </a>
            <ScrollLink
              to={
                each === 'Reviews'
                  ? 'clients'
                  : each === "FAQ's"
                  ? 'faqs'
                  : each === 'Contact Us'
                  ? 'contactUs'
                  : each.toLowerCase()
              }
              spy={true}
              smooth={true}
              duration={500}
              offset={linkOffsets[index]}
              className={subHeadingClasses}
              onClick={() => handleFooterlinkClick(each, index)}>
              <Typography
                variant={index === 0 ? 'subtitle1' : 'subtitle2'}
                style={{
                  fontFamily: index === 0 ? 'Plus Jakarta Sans, sans-serif' : 'ABeeZee, sans-serif',
                  fontSize: index === 0 ? undefined : '100%',
                  fontWeight: index === 0 ? undefined : 100,
                }}>
                {each}
              </Typography>
            </ScrollLink>
          )}
        </div>
      ))}
    </div>
  );
};

const FooterSection = () => {
  const { t } = useTranslation();
  const isLessThan640 = useMediaQuery('(max-width: 640px)');
  const isLessThan1150 = useMediaQuery('(max-width: 1150px)');
  const firstRowData = [
    t('links'),
    t('services'),
    t('expertise'),
    t('projects'),
    t('reviews'),
    t('faqs'),
    t('contact_us'),
  ];
  const secondRowData = [t('services'), t('web_dev'), t('mobile_dev'), t('ui_ux'), t('software_consultation')];

  const logoClass = isLessThan1150 ? 'flex flex-col w-42' : 'flex flex-col w-32';

  return (
    <div className="bg-footer-bg">
      <GapAround spaceTaken={1.5}>
        <section className="fixed-width-container py-10">
          <div className="flex space-x-48 breaktwo:space-x-0 justify-center items-center breaktwo:flex-col breaktwo:items-start breaktwo:px-20">
            <div className="">
              <div className={logoClass}>
                <img className="max-w-full h-auto breaktwo:h-28" src={whiteLogo} alt="logo" />
                <div className="flex mt-4 justify-center gap-2">
                  {/* <FacebookRoundedIcon
                      sx={{ cursor: 'pointer', '&:hover': { color: '#F5CA09', transition: 'color 0.2s ease' } }}
                    /> */}
                  {/* <a href="https://www.instagram.com/atinistudio/" target="_blank">
                    <InstagramIcon
                      sx={{ cursor: 'pointer', '&:hover': { color: '#F5CA09', transition: 'color 0.2s ease' } }}
                    />
                  </a>
                  <a href="https://www.linkedin.com/company/atini-studio-inc" target="_blank">
                    <LinkedInIcon
                      sx={{ cursor: 'pointer', '&:hover': { color: '#F5CA09', transition: 'color 0.2s ease' } }}
                    />
                  </a> */}
                </div>
              </div>
            </div>
            <div className="flex space-x-24 breaktwo:text-sm breaktwo:mt-8 breaktwo:space-x-20">
              <div className="h-full">
                <FooterColumn data={firstRowData} />
              </div>
              <div className="h-full">
                <FooterColumn data={secondRowData} noCursor />
              </div>
            </div>
          </div>
        </section>
      </GapAround>
      <div className="text-white flex justify-between items-center sm:items-between bg-light-black w-screen py-1 px-5 md:px-10 text-xs flex-col md:flex-row lg:px-28 sm:text-sm">
        <div>&copy; Atini Studio {new Date().getFullYear()}. All rights reserved.</div>
        <div className="flex gap-5">
          <a
            href="#"
            onClick={() => {
              const w = window as any;
              w.displayPreferenceModal();
              return false;
            }}
            id="termly-consent-preferences">
            Cookie Preferences
          </a>
          <a href="https://app.termly.io/document/privacy-policy/bcd1e0db-e936-45ba-b6b0-874dfe47f9a0" target="_blank">
            Privacy Policy
          </a>
          <a
            href="https://app.termly.io/document/terms-of-service/0ed1f37e-4c9a-49e0-aa57-6499949a524a"
            target="_blank">
            Terms of Service
          </a>
        </div>
      </div>
    </div>
  );
};

export default FooterSection;
