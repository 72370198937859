import { useTranslation } from 'react-i18next';
import Info from '../../components/common/Info';
import GapAround from '../../components/hoc/GapAround';
import { useMediaQuery } from '@mui/material';
import { imageUrl } from '../../utils';

const TrustedBySection = () => {
  const everydayInclusionLogo = imageUrl('everyday_inclusion_logo.jpg');
  const leadAutopilotLogo = imageUrl('lead_autopilot.png');
  const apexcareLogo = imageUrl('apexcare_logo.png');
  const aresLogo = imageUrl('ares_logo.png');
  const upkeepLogo = imageUrl('upkeep_logo.png');
  const { t } = useTranslation();
  const isLessThan640 = useMediaQuery('(max-width: 640px)');
  const isLessThan1280 = useMediaQuery('(max-width: 1280px)');

  const trustedCompanies = [everydayInclusionLogo, leadAutopilotLogo, apexcareLogo, aresLogo, upkeepLogo];

  return (
    <div className="pt-16 pb-20 border-y-2 border-t-0 breaktwo:pt-8 breaktwo:pb-8">
      <GapAround spaceTaken={1.5}>
        <Info
          typographyStyles={{ fontWeight: 500, fontSize: isLessThan1280 ? (isLessThan640 ? 28 : 30) : 36 }}
          containerClasses={'text-center mt-5 mb-6 md:mb-16'}>
          {t('trusted_by')}
        </Info>
        <div className="flex justify-center items-center flex-wrap gap-6 md:gap-12">
          {trustedCompanies.map((each, index) => {
            return (
              <div
                key={`trusted${index}`}
                className="flex justify-center items-center w-24 md:w-32 lg:w-40 mt-0 md:-mt-10">
                <img key={index} src={each} className="max-w-full" alt="" />
              </div>
            );
          })}
        </div>
      </GapAround>
    </div>
  );
};

export default TrustedBySection;
