import { useMediaQuery } from '@mui/material';
import classNames from 'classnames';
import { TwContainer } from '../../typings/tw.types';
import { imageUrl } from '../../utils';
import { DoubleBorderButton } from '../buttons/DoubleBorderButton';
import SubInfo from './SubInfo';
import Pill from './Pill';

interface ProjectCardProps extends TwContainer {
  name: string;
  icon: string;
  description: string;
  tech: string;
  type: string;
}

const ProjectCard = ({ name, icon, description, tech, type, containerClasses }: ProjectCardProps) => {
  const isLessThan640 = useMediaQuery('(max-width: 640px)');

  return (
    <div className={classNames('pb-12 flex flex-col', containerClasses)}>
      <img className="h-[200px] object-cover mb-2 sm:mb-4 rounded" src={imageUrl(icon)} alt=""></img>
      <div className="h-[20%] px-2">
        {/* <Tooltip title={description} placement="top-start"> */}
        {/* <div className="w-4/5"> */}
        <SubInfo
          typographyStyles={{
            fontSize: isLessThan640 ? 18 : 22,
            color: '#263238',
            fontWeight: 500,
          }}>
          {name}
        </SubInfo>
        <SubInfo
          // containerClasses="whitespace-nowrap text-ellipsis overflow-hidden"
          typographyStyles={{ color: '#263238', fontSize: isLessThan640 ? 14 : 16, fontStyle: 'italic' }}>
          {description}
        </SubInfo>
        <div className="flex gap-2 my-2 flex-wrap">
          <Pill containerClasses="bg-light-green" typographyStyles={{ color: '#fff' }}>
            {type}
          </Pill>
          {tech.split(' | ').map((t, i) => (
            <Pill
              key={i}
              containerClasses="bg-yellow"
              typographyStyles={{ color: '#728F8B', fontSize: isLessThan640 ? 10 : 12 }}>
              {t}
            </Pill>
          ))}
        </div>
        {/* </div> */}
        {/* </Tooltip> */}
        {/* <div className="w-1/5 flex justify-end pr-4">
          <DoubleBorderButton />
        </div> */}
      </div>
    </div>
  );
};

export default ProjectCard;
