import { useTranslation } from 'react-i18next';
import Info from '../../components/common/Info';
import ResultBox from '../../components/common/ResultBox';
import RoundInfo from '../../components/common/RoundInfo';
import SubInfo from '../../components/common/SubInfo';
import GapAround from '../../components/hoc/GapAround';
import { imageUrl } from '../../utils';
import { useMediaQuery } from '@mui/material';

const WhyUs = () => {
  const rocketImage = imageUrl('rocket.svg');
  const { t } = useTranslation();
  const isLessThan640 = useMediaQuery('(max-width: 640px)');
  const isLessThan1150 = useMediaQuery('(max-width: 1150px)');
  const isLargeThan899 = useMediaQuery('(min-width: 899px)');
  const isLessThan1280 = useMediaQuery('(max-width: 1280px)');

  const resultBoxClasses = isLessThan640
    ? 'grid grid-cols-1 gap-4 mx-6 mb-6'
    : 'flex justify-between space-x-10 breaktwo:flex-col breaktwo:space-x-0 breaktwo:items-center breaktwo:gap-4 breaktwo:mb-10 mx-4 sm:mx-0';

  const gapAroundClass =
    isLessThan1150 && isLargeThan899
      ? { paddingLeft: '60px', paddingRight: '50px', marginTop: '1rem' }
      : { marginTop: '1rem' };

  return (
    <GapAround spaceTaken={1.5} containerStyle={gapAroundClass}>
      <div
        className="w-full flex flex-col space-y-24 breaktwo:space-y-12 before:bg-top before:absolute before:w-full before:h-full before:-z-10 before:breaktwo:bg-[url('https://firebasestorage.googleapis.com/v0/b/atini-landing-page.appspot.com/o/assets%2Frocket.svg?alt=media')] before:opacity-20"
        id="whyus">
        <div className="flex flex-col breaktwo:items-center space-y-4 mt-0 md:mt-10 breaktwo:text-center">
          <RoundInfo containerClasses="mt-8">{t('scale_your_business')}</RoundInfo>
          <Info
            containerClasses="w-[90%] breaktwomin:w-[50%]"
            typographyStyles={{
              fontSize: isLessThan1280 ? (isLessThan640 ? 32 : 36) : 42,
            }}>
            {t('dedicated_team')}
          </Info>
          <div className="w-[90%] pb-4 breaktwomin:w-[50%]">
            <SubInfo>{t('top_talent_around_word')}</SubInfo>
          </div>
        </div>
        <div className="absolute right-[18%] bottom-52 -z-10 breaktwo:hidden">
          <img src={rocketImage} alt="" />
        </div>
      </div>

      <div className={resultBoxClasses}>
        <ResultBox
          containerClasses="bg-light-green/[0.85] p-5 xl:p-10"
          heading="500k+"
          subheading={t('revenue_tracked')}
          describeImage="earnings.svg"
          headingStyle={{ color: 'white', fontSize: isLessThan1280 ? (isLessThan1150 ? 48 : 54) : 60 }}
          subHeadingStyle={{ color: 'white', fontSize: isLessThan1150 ? 15 : 16 }}
          iconStyle={{ width: isLessThan1150 ? 40 : 50, height: isLessThan1150 ? 40 : 50 }}
        />
        <ResultBox
          containerClasses="bg-light-gray/[0.45] p-5 xl:p-10"
          heading="4.9+/5"
          subheading={t('average_rating')}
          describeImage="stars.svg"
          headingStyle={{ fontSize: isLessThan1280 ? (isLessThan1150 ? 48 : 54) : 60 }}
          subHeadingStyle={{ fontSize: isLessThan1150 ? 15 : 16 }}
          iconStyle={{ width: isLessThan1150 ? 40 : 45, height: isLessThan1150 ? 40 : 45 }}
        />
        <ResultBox
          containerClasses="bg-light-gray/[0.2] p-5 xl:p-10"
          heading="60+"
          subheading={t('happy_clients')}
          describeImage="happyfaces.svg"
          headingStyle={{ fontSize: isLessThan1280 ? (isLessThan1150 ? 48 : 54) : 60 }}
          subHeadingStyle={{ fontSize: isLessThan1150 ? 15 : 16 }}
          iconStyle={{ width: isLessThan1150 ? 50 : 60, height: isLessThan1150 ? 50 : 60 }}
        />
      </div>
    </GapAround>
  );
};

export default WhyUs;
