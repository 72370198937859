import ClientsSection from '../views/landing/ClientsSection';
import ContactUs from '../views/landing/ContactUs';
import FaqSection from '../views/landing/FaqSection';
import HeaderSection from '../views/landing/HeaderSection';
import OurExpertise from '../views/landing/OurExpertise';
import ProjectsSection from '../views/landing/ProjectsSection';
import ServicesSection from '../views/landing/ServicesSection';
import StepsWeFollow from '../views/landing/StepsWeFollow';
import WhyUs from '../views/landing/WhyUs';

const LandingPage = () => {
  return (
    <>
      <HeaderSection />
      <WhyUs />
      <ServicesSection />
      <OurExpertise />
      <StepsWeFollow />
      <ProjectsSection />
      <ClientsSection />
      <FaqSection />
      <ContactUs />
    </>
  );
};

export default LandingPage;
