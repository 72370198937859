import { RoundedArrowButton } from '../../components/buttons';
import WithNavbarHeightApplied from '../../components/hoc/WithNavbarHeightApplied';
import GapAround from '../../components/hoc/GapAround';
import BigColumn from '../../components/layout/BigColumn';
import { imageUrl } from '../../utils';
import SubInfo from '../../components/common/SubInfo';
import Info from '../../components/common/Info';
import RoundInfo from '../../components/common/RoundInfo';
import TrustedBySection from './../../views/landing/TrustedBySection';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import { scroller } from 'react-scroll';

const tiltedFlower = imageUrl('tilt-flower.svg');
const ladderToCup = imageUrl('ladder-to-cup.svg');

const HeaderSection = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isLessThan640 = useMediaQuery('(max-width: 640px)');
  const isLessThan1150 = useMediaQuery('(max-width: 1150px)');
  const isLargeThan899 = useMediaQuery('(min-width: 899px)');
  const isLessThan1280 = useMediaQuery('(max-width: 1280px)');
  const bigColumnClass = isLessThan1150 && isLargeThan899 ? { paddingLeft: '60px', paddingRight: '50px' } : undefined;

  return (
    <div>
      <WithNavbarHeightApplied>
        <GapAround spaceTaken={1.5}>
          <BigColumn containerStyle={bigColumnClass}>
            {/* left information section start */}
            <div className="breaktwo:flex breaktwo:flex-col justify-center items-center breaktwo:text-center before:bg-top before:absolute before:w-full before:h-full before:-z-10 before:breaktwo:bg-[url('https://firebasestorage.googleapis.com/v0/b/atini-landing-page.appspot.com/o/assets%2Fladder-to-cup.svg?alt=media')] before:opacity-20">
              <RoundInfo containerClasses="breaktwo:mb-3 mb-6 mt-32 breaktwo:mt-6">{t('our_team_your_team')}</RoundInfo>
              <Info
                typographyStyles={{
                  fontSize: isLessThan1280 ? (isLessThan640 ? 32 : 36) : 42,
                }}>
                {t('motto')}
              </Info>

              <SubInfo
                containerClasses="w-[80%] my-4"
                typographyStyles={{ fontFamily: 'ABeeZee, sans-serif' }}>
                {t('who_are_we')}
              </SubInfo>

              <div className="breaktwo:mt-5 mt-10">
                <RoundedArrowButton
                  movingArrow
                  text={t('get_instant_quote')}
                  containerClasses="bg-yellow w-72"
                  onClick={() => {
                    // navigate('/instant-quote');
                    scroller.scrollTo('contactUs', {
                      smooth: true,
                      duration: 500,
                      offset: -200,
                    });
                  }}
                />
              </div>
            </div>
            {/* left information section start */}

            {/* right image section start */}
            <div className="w-full h-full flex items-center -z-10 justify-end breaktwo:hidden mt-5">
              <img alt="" className="h-full w-[500px]" src={ladderToCup} />
            </div>
            {/* right image section end */}
          </BigColumn>
        </GapAround>
        <TrustedBySection />
      </WithNavbarHeightApplied>
    </div>
  );
};

export default HeaderSection;
