import { useTranslation } from 'react-i18next';
import Info from '../../components/common/Info';
import SubInfo from '../../components/common/SubInfo';
import GapAround from '../../components/hoc/GapAround';
import Carousel from '../../components/common/Carousel';
import { imageUrl } from '../../utils';
import { useMediaQuery } from '@mui/material';

const expertiseArray = [
  {
    name: 'React/React Native',
    icon: 'react_native_logo.svg',
  },
  {
    name: 'Angular',
    icon: 'angular_logo.svg',
  },
  {
    name: 'TypeScript',
    icon: 'typescript_logo.png',
  },
  {
    name: 'Next.js',
    icon: 'nextjs_logo.svg',
  },
  {
    name: 'GraphQL',
    icon: 'graphql_logo.svg',
  },
  {
    name: 'HTML',
    icon: 'html_logo.svg',
  },
  {
    name: 'CSS',
    icon: 'css_logo.svg',
  },
  {
    name: 'Tailwind CSS',
    icon: 'tailwind_css_logo.svg',
  },
  {
    name: 'Material UI',
    icon: 'material_ui_logo.svg',
  },
  {
    name: 'Jest',
    icon: 'jest_logo.png',
  },
  {
    name: 'MySQL',
    icon: 'mysql_logo.png',
  },
  {
    name: 'NestJS',
    icon: 'nestjs_logo.svg',
  },
  {
    name: 'Vue.js',
    icon: 'vuejs_logo.svg',
  },
  {
    name: 'Node.js',
    icon: 'nodejs_logo.svg',
  },
  {
    name: 'MongoDB',
    icon: 'mongodb_logo.svg',
  },
  {
    name: 'PostgreSQL',
    icon: 'postgresql_logo.svg',
  },
  {
    name: 'Redux',
    icon: 'redux_logo.png',
  },
  {
    name: 'Amazon Web Services',
    icon: 'aws_logo.svg',
  },
  {
    name: 'Firebase',
    icon: 'firebase_logo.jpg',
  },
  {
    name: 'Express.js',
    icon: 'express_logo.svg',
  },
  {
    name: 'Figma',
    icon: 'figma_logo.svg',
  },
  {
    name: 'Adobe XD',
    icon: 'adobexd_logo.svg',
  },
  {
    name: 'TypeORM',
    icon: 'typeorm_logo.png',
  },
  {
    name: 'GitHub',
    icon: 'github_logo.svg',
  },
];

const OurExpertise = () => {
  const { t } = useTranslation();

  const isLessThan800 = useMediaQuery('(max-width: 800px)');
  const isLessThan1280 = useMediaQuery('(max-width: 1280px)');
  const isLessThan640 = useMediaQuery('(max-width: 640px)');

  return (
    <div className="outline outline-[1px] outline-gray-200 my-20 breaktwo:my-10" id="expertise">
      <GapAround spaceTaken={1.5} childrenClasses="flex flex-col breaktwomin:flex-row h-full items-center">
        <div className="left_part w-full h-full py-20 md:pl-16 breaktwomin:pr-16 space-y-4 text-center breaktwomin:text-left flex flex-col justify-center breaktwo:py-8">
          <Info typographyStyles={{ color: '#263238', fontSize: isLessThan1280 ? (isLessThan640 ? 32 : 36) : 42 }}>{t('our_expertise')}</Info>
          <SubInfo typographyStyles={{ color: '#728F8B' }}>{t('most_up_to_date_tech')}</SubInfo>
        </div>
        <div className="right_part w-[60%] breaktwo:w-4/5 flex items-center">
          <div className="cursor-pointer swiper-go-left-enterprise w-80 mr-4">
            <img src={imageUrl('arrow_left.svg')} alt=""></img>
          </div>
          <Carousel
            dataArray={expertiseArray}
            rowsPerSlide={2}
            slidesPerView={isLessThan800 ? 1 : 3}
            leftBtnClassName={'swiper-go-left-enterprise'}
            rightBtnClassName={'swiper-go-right-enterprise'}
            calledFrom="expertise"
            border
            autoplay
          />
          <div className="cursor-pointer swiper-go-right-enterprise w-80 ml-4">
            <img src={imageUrl('arrow_right.svg')} alt=""></img>
          </div>
        </div>
      </GapAround>
    </div>
  );
};

export default OurExpertise;
