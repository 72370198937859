import Info from '../../components/common/Info';
import GapAround from '../../components/hoc/GapAround';
import QuestionAnswer from '../../components/common/QuestionAnswer';
import SubInfo from '../../components/common/SubInfo';
import { RoundedArrowButton } from '../../components/buttons';
import { imageUrl } from '../../utils';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mui/material';
import { Link as ScrollLink, scroller } from 'react-scroll';

const questionAnswerArray = [
  {
    question:
      'Why should I choose to hire an agency if I can just hire an individual freelancer at a potentially lower price?',
    answer:
      "Great question, think of this - would you buy a name brand from an authentic store or directly from its supplier? The three biggest benefits of working with an agency are: quality control, fulfilling timeline, efficiency. We are experts in the field and we know right away when we see cheap code. Additionally, we manage our team with agile methodology, allowing us to be on track and meet deadlines, and we are always flexible with adjusting the number of assigned developers to your project. Last but not least, we closely monitor efficiency, we know what's a reasonable estimate on a certain task and help you keep track of both time and budget.",
  },
  {
    question: 'Where are your developers based?',
    answer:
      'While our headquarter is in the golden state in Los Angeles, we hand pick the best talents across the globe. If you have preference on location then feel free to let us know, we will be able to fulfill your request.',
  },
  {
    question: 'Can I work directly with your developers?',
    answer:
      'Definitely, all of our developers can communicate in English fluently, and we love it when our clients interact with our team at a more personal level.',
  },
  {
    question: 'I have an idea, how do I start?',
    answer:
      "Fantastic, you land at the right place! We have years of experiences in building projects from scratch - from consultation to design, development, then delivery. Feel free to contact us, we are here to turn your idea into reality, and we've successfully done this plenty of times.",
  },
  {
    question: "What's the usual turnaround on projects?",
    answer:
      "Turnaround can vary a lot depending on project size and urgency. We will be able to assign the number of developers involved in your project accordingly, and trust us, we're good at it!",
  },
  {
    question: 'I have a team now but am looking for other options / evaluating my current team, what do you suggest?',
    answer:
      "First of all, make sure that you have access to and own everything, most importantly, the codebase. However, if you have missing pieces, rest assured we'll help you put those pieces together. We can help evaluate quality of current codebase, put together what you need during the transition, and draw out plan to prevent all foreseeable issues. We're here to solve problems for you.",
  },
];

const FaqSection = () => {
  const { t } = useTranslation();

  const handleTalkToExpertClick = () => {
    scroller.scrollTo('contactUs', {
      duration: 500,
      smooth: true,
      offset: -200,
    });
  };
  const isLessThan640 = useMediaQuery('(max-width: 640px)');
  const isLessThan1280 = useMediaQuery('(max-width: 1280px)');

  return (
    <GapAround spaceTaken={1.5} childrenClasses="bg-very-light-gray rounded-xl flex items-center flex-col relative">
      <Info
        id="faqs"
        containerClasses="text-center pt-10 mb-5 md:mb-10"
        typographyStyles={{ color: '#263238', fontSize: isLessThan1280 ? (isLessThan640 ? 32 : 36) : 42 }}>
        {t('faq')}
      </Info>

      {questionAnswerArray.map((each, index) => {
        return <QuestionAnswer question={each.question} answer={each.answer} key={`qa${index}`} />;
      })}

      <img
        className="absolute -top-8 right-10 z-1 h-16 sm:h-auto"
        src={imageUrl('question_mark_double_border.svg')}
        alt="question-mark"
      />
      <img
        className="absolute left-10 sm:left-20 top-20 sm:top-24 scale-50"
        src={imageUrl('question_mark_double_border.svg')}
        alt="question-mark"
      />
      <img
        className="absolute bottom-[20%] right-10 scale-50 -rotate-45 breaktwo:hidden"
        src={imageUrl('question_mark_double_border.svg')}
        alt="question-mark"
      />

      <div className="flex justify-between w-9/12 border-t-2 py-6 sm:py-10 mt-6 sm:mt-10 breaktwo:flex-col breaktwo:items-center breaktwo:text-center">
        <div>
          <SubInfo typographyStyles={{ fontWeight: 500, color: '#263238', fontSize: 25 }}>
            {t('have_more_questions')}
          </SubInfo>
          <SubInfo typographyStyles={{ color: '#728F8B' }} containerClasses="mt-1">
            {t('get_in_touch')}
          </SubInfo>
        </div>
        <div>
          <RoundedArrowButton
            text={t('talk_to_expert')}
            typographyStyles={{ fontWeight: 300 }}
            arrowContainerClasses="ml-10"
            containerClasses="breaktwo:w-70 breaktwo:mt-6"
            onClick={handleTalkToExpertClick}
          />
        </div>
      </div>
    </GapAround>
  );
};

export default FaqSection;
