import classNames from 'classnames';
import GapAround from '../../components/hoc/GapAround';
import { TwContainer } from '../../typings/tw.types';
import Info from '../common/Info';
import RoundInfo from '../common/RoundInfo';
import { useMediaQuery } from '@mui/material';

interface BigCardProps extends TwContainer {
  topLeftElement?: JSX.Element;
  topRightElement?: JSX.Element;
  bottomElement?: JSX.Element;
  spaceTaken?: number;
  roundInfoText?: string;
  mainInfoText?: string;
  bottomContainerClasses?: string;
  topRightContainerClasses?: string;
  noBorder?: boolean;
}

const BigCard = ({
  topRightElement,
  bottomElement,
  spaceTaken = 0.5,
  containerClasses,
  roundInfoText = 'Please provide text here',
  mainInfoText = 'Please provide text here',
  bottomContainerClasses,
  topRightContainerClasses,
  noBorder = false,
  id,
}: BigCardProps) => {
  const isLessThan640 = useMediaQuery('(max-width: 640px)');
  const isLessThan1280 = useMediaQuery('(max-width: 1280px)');

  return (
    <GapAround spaceTaken={spaceTaken} childrenClasses="h-full mb-10 breakone:mb-0">
      <div
        className={classNames('rounded-[10px] flex flex-col items-center pt-10 breaktwo:pt-0', containerClasses)}
        id={id}>
        <div
          className={classNames(
            'w-9/12 breaktwo:w-full flex items-end justify-between border-light-black pb-8 breaktwomin:pb-12 breakthree:flex-col breaktwo:items-center breaktwo:text-center',
            {
              'border-b-[1.5px]': !noBorder,
            },
          )}>
          <div className="w-2/3 breaktwo:w-full breaktwo:flex breaktwo:flex-col breaktwo:items-center">
            <RoundInfo containerClasses="mb-5 mt-10">{roundInfoText}</RoundInfo>
            <Info typographyStyles={{ color: '#263238', fontSize: isLessThan1280 ? (isLessThan640 ? 32 : 36) : 42 }}>{mainInfoText}</Info>
          </div>
          <div className={classNames('w-1/3 breaktwo:w-full', topRightContainerClasses)}>{topRightElement}</div>
        </div>
        <div
          className={classNames(bottomContainerClasses, 'pb-10 flex', {
            'pt-10': !noBorder,
          })}>
          {bottomElement}
        </div>
      </div>
    </GapAround>
  );
};

export default BigCard;
