import { ThemeProvider } from '@mui/material/styles';
import LandingPage from './pages/LandingPage';
import WithNavbarAndFooter from './components/hoc/WithNavbarAndFooter';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { theme } from './constants';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import EnglishTranslation from './i18n/en.json';
import ChineseTranslation from './i18n/zh.json';
import { Fragment } from 'react';
import InstantQuote from './pages/InstantQuote';
import OurTeamPage from './pages/OurTeamPage';
import FooterSection from './views/landing/FooterSection';
import { FlowerOne, FlowerThree, FlowerTwo } from './components/common/Flowers';
import classNames from 'classnames';

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    supportedLngs: ['en', 'zh'],
    resources: {
      en: {
        translation: EnglishTranslation,
      },
      zh: {
        translation: ChineseTranslation,
      },
    },
    fallbackLng: 'en',
    detection: {
      order: ['path', 'querystring', 'cookie', 'htmlTag', 'localStorage', 'path', 'subdomain'],
      caches: ['cookie'],
    },
  });

function App() {
  return (
    <div style={{ position: 'relative' }}>
      <ThemeProvider theme={theme}>
        <Router>
          <div className={classNames(`relative fixed-width-container`)}>
            <Fragment>
              <Routes>
                <Route
                  path="/"
                  element={
                    <WithNavbarAndFooter>
                      <LandingPage />
                    </WithNavbarAndFooter>
                  }
                />
                {/* <Route
                  path="instant-quote"
                  element={
                    <WithNavbarAndFooter>
                      <InstantQuote />
                    </WithNavbarAndFooter>
                  }
                /> */}
                {/* <Route
              path="our-team"
              element={
                <WithNavbarAndFooter>
                  <OurTeamPage />
                </WithNavbarAndFooter>
              }
            /> */}
              </Routes>
            </Fragment>
          </div>
          <FooterSection />
          <FlowerOne />
          <FlowerTwo />
          <FlowerThree />
        </Router>
      </ThemeProvider>
    </div>
  );
}

export default App;
