import { Typography } from '@mui/material';
import { RoundedArrowButton } from '../buttons';
import GapAround from '../hoc/GapAround';
import classNames from 'classnames';
import { imageUrl } from '../../utils';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Link as ScrollLink, scroller } from 'react-scroll';
import { Cross as Hamburger } from 'hamburger-react';
import { useCallback, useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { linkOffsets } from '../../utils';

const coloredLogo = imageUrl('atini-logo-colored.svg');
const navbarMotion = {
  rest: { ease: 'easeOut', duration: 0.2, type: 'tween', x: '100vw' },
  drawer: {
    x: 0,
    transition: {
      duration: 0.2,
      type: 'tween',
      ease: 'easeIn',
    },
  },
};

const Navbar = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const isHomePage = location.pathname === '/';

  const TwLink = ({ children }: any) => {
    return (
      <div
        className="cursor-pointer py-2 hover:bg-yellow"
        onClick={() => {
          setDrawerOpen(false);
        }}>
        {children}
      </div>
    );
  };

  useEffect(() => {
    let handleResize = (e: any) => {
      setDrawerOpen(false);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const commonNavlinkClasses = 'hover:underline underline-offset-8 decoration-yellow decoration-2	text-center';
  const navigate = useNavigate();

  const getIndexFromParam = (param: string) => {
    switch (param) {
      case 'services':
        return 1;
      case 'expertise':
        return 2;
      case 'projects':
        return 3;
      case 'clients':
        return 4;
      case 'faqs':
        return 5;
      default:
        return 0;
    }
  };

  const handleNavlinkClick = useCallback(
    (param: string) => {
      if (param === 'contactUs') {
        scroller.scrollTo('contactUs', {
          smooth: true,
          duration: 500,
          offset: -200,
        });
      }
      if (isHomePage) return;
      navigate('/');
      setTimeout(() => {
        document.getElementById(`${param}link`)?.click();
        const index = getIndexFromParam(param);
        const offset = linkOffsets[index];
        window.scrollTo({ top: offset, behavior: 'smooth' });
      }, 500);
    },
    [isHomePage, location.pathname, navigate],
  );

  return (
    <div className="fixed top-0 left-0 right-0 bg-white z-10 fixed-width-container md:px-16">
      <GapAround spaceTaken={2}>
        <div className="flex items-center h-[7rem] md:h-navbarHeight justify-center mdmax:justify-around relative">
          <div className="flex justify-center items-center">
            <div className="hidden mdmax:flex">
              <Hamburger toggled={drawerOpen} onToggle={setDrawerOpen} size={22} />
            </div>
            {/* this is for logo image */}
            <Link to={'/'}>
              <div className="min-w-[10rem] cursor-pointer breakone:min-w-[5rem] lgmax:min-w-[7rem]">
                <img className={classNames('h-[calc(theme(space.navbarHeight)-50px)]')} src={coloredLogo} alt="logo" />
              </div>
            </Link>
          </div>
          {/* this is for logo image end */}

          {/* this is for the nav links present */}

          <div className="flex space-x-6 breaktwomin:space-x-8 lg:space-x-12 breakonemin:space-x-14 mdmax:hidden">
            <ScrollLink
              to="services"
              spy={true}
              smooth={true}
              offset={linkOffsets[getIndexFromParam('services')]}
              duration={500}
              id="serviceslink"
              onClick={() => handleNavlinkClick('services')}>
              <div className={classNames('cursor-pointer', commonNavlinkClasses)}>
                <Typography variant="button">{t('services')}</Typography>
              </div>
            </ScrollLink>
            <ScrollLink
              to="expertise"
              spy={true}
              smooth={true}
              offset={linkOffsets[getIndexFromParam('expertise')]}
              duration={500}
              id="expertiselink"
              onClick={() => handleNavlinkClick('expertise')}>
              <div className={classNames('cursor-pointer', commonNavlinkClasses)}>
                <Typography variant="button">{t('expertise')}</Typography>
              </div>
            </ScrollLink>
            <ScrollLink
              to="projects"
              spy={true}
              smooth={true}
              offset={linkOffsets[getIndexFromParam('projects')]}
              duration={500}
              id="projectslink"
              onClick={() => handleNavlinkClick('projects')}>
              <div className={classNames('cursor-pointer', commonNavlinkClasses)}>
                <Typography variant="button">{t('projects')}</Typography>
              </div>
            </ScrollLink>
            <ScrollLink
              to="clients"
              spy={true}
              smooth={true}
              offset={linkOffsets[getIndexFromParam('clients')]}
              duration={500}
              id="clientslink"
              onClick={() => handleNavlinkClick('clients')}>
              <div className={classNames('cursor-pointer', commonNavlinkClasses)}>
                <Typography variant="button">{t('reviews')}</Typography>
              </div>
            </ScrollLink>
            <div className="pr-10 lg:pr-14 breakonemin:pr-16">
              <ScrollLink
                to="faqs"
                spy={true}
                smooth={true}
                offset={linkOffsets[getIndexFromParam('faqs')]}
                duration={500}
                id="faqslink"
                onClick={() => handleNavlinkClick('faqs')}>
                <div className={classNames('cursor-pointer', commonNavlinkClasses)}>
                  <Typography variant="button">{t('faqs')}</Typography>
                </div>
              </ScrollLink>
            </div>
            {/* <div className={classNames('cursor-pointer', commonNavlinkClasses)}>
              <Link to="/our-team">
                <Typography variant="button">{t('teams')}</Typography>
              </Link>
            </div> */}
          </div>
          {/* <RoundedArrowButton
            text={t('contact_us')}
            arrowContainerClasses="ml-10"
            containerClasses="lgmax:hidden"
            jumpToDivId="contactUs"></RoundedArrowButton> */}
          {/* this is for the nav links present end */}
          <ScrollLink to="contactUs" spy={true} smooth={true} duration={500} id="contactUslink" offset={200}>
            <RoundedArrowButton
              text={t('contact_us')}
              arrowContainerClasses="ml-10"
              containerClasses="mdmax:hidden"
              onClick={() => handleNavlinkClick('contactUs')}
            />
          </ScrollLink>

          <div className="hidden lgmax:flex lgmax:items-center lgmax:gap-2">
            <div className="md:hidden">
              <RoundedArrowButton text={t('contact_us')} containerClasses="mr-2" arrowContainerClasses="ml-10" onClick={() => handleNavlinkClick('contactUs')}></RoundedArrowButton>
            </div>
            <AnimatePresence>
              {drawerOpen && (
                <motion.div
                  className="flex flex-col text-center absolute left-0 right-0 top-28 min-w-[300px] bg-gray-100 py-4 gap-2 rounded-lg mx-10"
                  variants={navbarMotion}
                  initial={{ x: -500 }}
                  animate="drawer"
                  exit="rest">
                  <>
                    <ScrollLink
                      to="services"
                      spy={true}
                      smooth={true}
                      offset={-200}
                      duration={500}
                      onClick={() => handleNavlinkClick('services')}>
                      <TwLink>
                        <Typography variant="button">{t('services')}</Typography>
                      </TwLink>
                    </ScrollLink>
                    <ScrollLink
                      to="expertise"
                      spy={true}
                      smooth={true}
                      offset={-100}
                      duration={500}
                      onClick={() => handleNavlinkClick('expertise')}>
                      <TwLink>
                        <Typography variant="button">{t('expertise')}</Typography>
                      </TwLink>
                    </ScrollLink>
                    <ScrollLink
                      to="projects"
                      spy={true}
                      smooth={true}
                      offset={50}
                      duration={500}
                      onClick={() => handleNavlinkClick('projects')}>
                      <TwLink>
                        <Typography variant="button">{t('projects')}</Typography>
                      </TwLink>
                    </ScrollLink>
                    <ScrollLink
                      to="clients"
                      spy={true}
                      smooth={true}
                      offset={50}
                      duration={500}
                      onClick={() => handleNavlinkClick('clients')}>
                      <TwLink>
                        <Typography variant="button">{t('reviews')}</Typography>
                      </TwLink>
                    </ScrollLink>
                    <ScrollLink
                      to="faqs"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={500}
                      onClick={() => handleNavlinkClick('faqs')}>
                      <TwLink>
                        <Typography variant="button">{t('faqs')}</Typography>
                      </TwLink>
                    </ScrollLink>
                    {/* <Link to="/our-team">
                      <TwLink>
                        <Typography variant="button">{t('teams')}</Typography>
                      </TwLink>
                    </Link> */}
                  </>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </div>
      </GapAround>
    </div>
  );
};

export default Navbar;
