import { Typography } from '@mui/material';
import { imageUrl } from '../../utils';

interface ServiceCardProps {
  title: string;
  icon: string;
  description: string;
}

const ServiceCard = ({ title, icon, description }: ServiceCardProps) => {
  return (
    <div className="flex breaktwo:mb-2">
      <div className="h-28 w-[12%] overflow-hidden flex items-start mr-6 mt-2">
        <img className="" src={imageUrl(icon)} alt="" />
      </div>

      <div className="flex flex-col w-[88%] mb-6 breaktwo:mb-2">
        <div>
          <Typography
            variant="subtitle1"
            style={{ color: '#263238', fontWeight: 600, letterSpacing: 0.5, lineHeight: 1.3 }}>
            {title}
          </Typography>
        </div>
        <div>
          <Typography
            variant="subinformation"
            style={{ color: '#263238', fontSize: '.95em', fontFamily: 'ABeeZee, sans-serif' }}>
            {description}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default ServiceCard;
