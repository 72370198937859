import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Grid, Pagination, Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/grid';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { TwContainer } from '../../typings/tw.types';
import classNames from 'classnames';
import ExpertiseCard from './ExpertiseCard';
import ProjectCard from './ProjectCard';
import ClientCard from './ClientCard';

interface CarouselProps extends TwContainer {
  rowsPerSlide?: number;
  dataArray?: any[];
  slidesPerView?: number;
  pagination?: boolean;
  leftBtnClassName: string;
  rightBtnClassName: string;
  calledFrom: 'projects' | 'expertise' | 'clients';
  border?: boolean;
  spaceBetween?: number;
  loop?: boolean;
  autoplay?: boolean;
}

const Carousel = ({
  containerClasses,
  rowsPerSlide = 1,
  dataArray = [],
  slidesPerView = 2,
  pagination = false,
  leftBtnClassName,
  rightBtnClassName,
  calledFrom,
  border = false,
  spaceBetween = 0,
  loop = false,
  autoplay = false,
}: CarouselProps) => {
  return (
    <Swiper
      modules={[Navigation, Grid, Pagination, Autoplay]}
      navigation={{
        prevEl: `.${leftBtnClassName}`,
        nextEl: `.${rightBtnClassName}`,
      }}
      pagination={
        pagination
          ? {
              clickable: true,
            }
          : undefined
      }
      speed={500}
      slidesPerView={slidesPerView}
      className={classNames(containerClasses)}
      spaceBetween={spaceBetween}
      grid={{
        rows: rowsPerSlide,
        fill: 'row',
      }}
      loop={loop}
      autoplay={
        autoplay
          ? {
              delay: 2500,
              disableOnInteraction: false,
            }
          : undefined
      }>
      {dataArray.map((each, index) => {
        return (
          <SwiperSlide key={index}>
            {calledFrom === 'expertise' && (
              <ExpertiseCard
                containerClasses={classNames('outline outline-[.5px] outline-gray-200', {
                  'border-l-[1px]': index === 0 || index === dataArray.length / 2,
                  'border-r-[1px]': index === dataArray.length - 1 || index === dataArray.length / 2 - 1,
                })}
                {...each}
              />
            )}
            {calledFrom === 'projects' && <ProjectCard {...each} />}
            {calledFrom === 'clients' && <ClientCard {...each} />}
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default Carousel;
