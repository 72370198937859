import axios from 'axios';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;


interface Obj {
  url: string,
  data?: any,
  headers?: any
}

export const getApi = (obj: Obj) => {
  return axios.get(API_BASE_URL + obj.url, {
    params: {
      ...obj.data,
    },
  })
}

export const postApi = (obj: Obj) => {
  return axios.post(
    API_BASE_URL + obj.url,
    {
      ...obj.data,
    },
  );
}