import { useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Carousel from '../../components/common/Carousel';
import BigCard from '../../components/layout/BigCard';
import { imageUrl } from '../../utils';
import { RoundedArrowButton } from '../../components/buttons';

const clientsArray = [
  {
    name: 'Jason W.',
    description:
      'I have been working with Christine for several months now and I am very pleased with her knowledge, experience, and abilities. We have been developing a web application to help manage our internal operations and Christine has been able to bring my vision into a reality. Christine is thoughtful about the process and asks good questions to ensure she understands the objective and goals. She has a depth of knowledge in this area and I would recommend her for any web application development.',
    icon: 'apexcare_logo.png',
    role: 'President',
    linkedIn: 'https://www.linkedin.com/in/jason-wu-1a8891/',
  },
  {
    name: 'Alireza M.',
    description:
      'Christine has demonstrated exceptional skills in application development and has successfully created an app that we rely on daily to streamline our business operations. Her attention to detail, technical expertise, and dedication to meeting our specific requirements have been remarkable. Not only has Christine delivered a highly functional and user-friendly application, but she has also been proactive in addressing any issues or modifications we have requested...',
    // 'Christine has demonstrated exceptional skills in application development and has successfully created an app that we rely on daily to streamline our business operations. Her attention to detail, technical expertise, and dedication to meeting our specific requirements have been remarkable. Not only has Christine delivered a highly functional and user-friendly application, but she has also been proactive in addressing any issues or modifications we have requested. Her responsiveness and willingness to go the extra mile have made the development process smooth and efficient. On a personal level, I have thoroughly enjoyed collaborating with Christine. Her professionalism, communication skills, and ability to understand our business needs have made her an invaluable partner.',
    icon: 'ares_logo.png',
    role: 'Co-Founder',
    linkedIn: 'https://www.linkedin.com/in/alireza-mehrnia-84515881',
  },
  {
    name: 'Mike T.',
    description:
      "I had the opportunity to work with Christine and her team on a media uploader project with content moderation requirements, and I'm extremely satisfied with their work. They showcased their competence throughout the project, delivering a high-quality solution within the agreed upon timelines. The project required both front-end and back-end development, and the set up of cloudfront on aws. Christine and her team demonstrated a strong understanding of our specific needs and...",
    // "I had the opportunity to work with Christine and her team on a media uploader project with content moderation requirements, and I'm extremely satisfied with their work. They showcased their competence throughout the project, delivering a high-quality solution within the agreed upon timelines. The project required both front-end and back-end development, and the set up of cloudfront on aws. Christine and her team demonstrated a strong understanding of our specific needs and successfully translated them into an efficient and reliable media uploader. Their attention to detail and technical expertise were impressive. What stood out to me was their commitment to meeting deadlines. Christine consistently delivered milestones and updates on time, ensuring a smooth progress of the project. The final result of the media uploader and content moderation solution was excellent. Christine and her team successfully incorporated the necessary features, providing seamless uploading functionality and effective content moderation. The product's functionality and user-friendly interface reflect their exceptional work.",
    icon: 'memorabl_logo.png',
    role: 'Co-Founder',
    linkedIn: 'https://www.linkedin.com/in/mike-taft',
  },
  {
    name: 'Rustin R.',
    description:
      "Have worked with dozens of terrific developers on Upwork and Christine is among the best. She's responsive, UI savvy, and delivers a terrific end-product. Couldn't be happier with her work and would hire again in a millisecond.",
    icon: 'whatboard_logo.png',
    role: 'President',
    linkedIn: 'https://www.linkedin.com/in/rustin-ross-713a99238/',
  },
  {
    name: 'Dennis E.',
    description:
      'Christine is enjoyable to work with, quick to understand/asks productive questions, and performs quality work! Glad I connected with Christine.',
    icon: 'tourgolfleague_logo.png',
    role: 'Founder',
    linkedIn: 'https://www.linkedin.com/in/dennis-einspahr-b1b2085a/',
  },
];

const ClientsSection = () => {
  const { t } = useTranslation();
  const isLessThan960 = useMediaQuery('(max-width: 960px)');
  const BottomElement = (
    <>
      <div className="smmax:hidden cursor-pointer swiper-go-left-clients mr-8 flex items-center">
        <img className="w-20" src={imageUrl('arrow_left.svg')} alt=""></img>
      </div>
      <Carousel
        dataArray={clientsArray}
        rowsPerSlide={1}
        slidesPerView={isLessThan960 ? 1 : 2}
        leftBtnClassName={'swiper-go-left-clients'}
        rightBtnClassName={'swiper-go-right-clients'}
        calledFrom="clients"
        loop
        autoplay
      />
      <div className="smmax:hidden cursor-pointer swiper-go-right-clients ml-8 flex items-center">
        <img className="w-20" src={imageUrl('arrow_right.svg')} alt=""></img>
      </div>
    </>
  );
  const TopRightElement = (
    <div className="flex flex-col w-full mr-0 breaktwo:mr-20 breakthree:mr-0 breakthree:items-center items-end">
      <img
        className="max-w-full breakthree:absolute breakthree:right-0 breakthree:top-80 breakthree:opacity-30 breakthree:-z-10 breaktwo:w-2/3 breaktwo:mt-5"
        src={imageUrl('happy_clients_bg.svg')}
        alt=""
      />
      <RoundedArrowButton
        text={t('view_more')}
        containerClasses="bg-light-black w-48 mt-2 breakone:mt-4"
        arrowColor="#263238"
        arrowContainerClasses="bg-white"
        typographyStyles={{ color: 'white', fontWeight: 'light' }}
        onClick={() => {
          window.open('https://www.upwork.com/ag/atinistudio/', '_blank');
        }}
      />
    </div>
  );

  return (
    <BigCard
      roundInfoText={t('satisfied_clients')}
      mainInfoText={t('what_clients_say')}
      noBorder
      topRightElement={TopRightElement}
      bottomElement={BottomElement}
      bottomContainerClasses="w-[82%]"
      topRightContainerClasses="flex justify-end"
      id="clients"
    />
  );
};

export default ClientsSection;
