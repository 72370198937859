import { Typography } from '@mui/material';
import classNames from 'classnames';
import { TwContainer } from '../../typings/tw.types';

const RoundInfo = ({ children, containerClasses }: TwContainer) => {
  return (
    <div className={classNames('bg-light-blue rounded-full text-center px-8 py-3 w-max uppercase', containerClasses)}>
      <Typography variant="body2" style={{ fontFamily: 'ABeeZee, Plus Jakarta Sans, sans-serif' }}>{children}</Typography>
    </div>
  );
};

export default RoundInfo;
